<template>
    <div class="Rsf drag" v-draw>
    <div class="evaluate-header">
      {{projactNanme}}_{{historyname}}
      <div class="evaluate-header-icon" @click="closeCompon">
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="Observer-box">
      <div class="Observer-box-tit">
        {{titname}}
      </div>
      <div class="Observer-box-flex flex">
        <div class="bserver-box-flex-left">
          <div class="left-list">
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">SP</div>
            <div class="list-flex1-num">
              {{infoList[node].SP}}
            </div>
          </div>
             <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">PV</div>
            <div class="list-flex1-num"
            style="color:#0de0ac"
            >
              {{infoList[node].PV}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AV</div>
            <div class="list-flex1-num"
            style="color:#ec163e"
            >
              {{infoList[node].AV}}
            </div>
          </div>
               <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TL1</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].TL1,'软伺服作用时间，下位已经全部将四个作为时间参数统一，只需修改TL1','TL1',node)"
            >
              {{infoList[node].TL1}}
            </div>
          </div>
        <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TL2</div>
            <div class="list-flex1-num"
            >
              {{infoList[node].TL2}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TL3</div>
            <div class="list-flex1-num"
            >
              {{infoList[node].TL3}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TL4</div>
            <div class="list-flex1-num"
            >
              {{infoList[node].TL4}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">E1</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].E1,'软伺服偏差区间1','E1',node)"
            >
              {{infoList[node].E1}}
            </div>
          </div>
                 <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">E2</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].E2,'软伺服偏差区间2','E2',node)"
            >
              {{infoList[node].E2}}
            </div>
          </div>
                  <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">E3</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].E3,'软伺服偏差区间4','E3',node)"
            >
              {{infoList[node].E3}}
            </div>
          </div>
                  <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">E4</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].E4,'软伺服偏差区间4','E4',node)"
            >
              {{infoList[node].E4}}
            </div>
          </div>
                 <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AO1</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].AO1,'软伺服偏差区间1对应输出幅度1','AO1',node)"
            >
              {{infoList[node].AO1}}
            </div>
          </div>
                <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AO2</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].AO2,'软伺服偏差区间2对应输出幅度2','AO2',node)"
            >
              {{infoList[node].AO2}}
            </div>
          </div>
                <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AO3</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].AO3,'软伺服偏差区间3对应输出幅度3','AO3',node)"
            >
              {{infoList[node].AO3}}
            </div>
          </div>
                <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AO4</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].AO4,'软伺服偏差区间4对应输出幅度4','AO4',node)"
            >
              {{infoList[node].AO4}}
            </div>
          </div>
               <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TL</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].TL,'防抖时间，同时对软伺服和重叠控制起作用','TL',node)"
            >
              {{infoList[node].TL}}
            </div>
          </div>
               <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">MD</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].MD,'输出控制量程下限','MD',node)"
            >
              {{infoList[node].MD}}
            </div>
          </div>
               <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">MU</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].MU,'输出控制量程上限','MU',node)"
            >
              {{infoList[node].MU}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">ZFK</div>
            <div class="list-flex1-num"
             @click="toIpt(infoList[node].ZF_K,'输出控制量程上限','ZF_K',node)"
            >
              {{infoList[node].ZF_K}}
            </div>
          </div>
          </div>
        </div>
        <!-- 折线图 -->
        <div class="bserver-box-flex-right flex">
           <div class="right-cen-echarts">
            <div id="rts_Chart2" v-loading="loading" :option="option"></div>
          </div>
          <div class="rts_Chart-right">
             <div class="lineCh" 
                >
                    <div>设定值</div>
                    <div>Y[{{infoList[node].SP}}]</div>
                    <div>Y[{{name[0]}}]</div>
                </div>
                  <div class="lineCh lineCh2" 
                >
                    <div>测量值</div>
                    <div>Y[{{infoList[node].PV}}]</div>
                    <div>Y[{{name[1]}}]</div>
                </div>
                  <div class="lineCh lineCh3" 
                >
                    <div>总输出</div>
                    <div>Y[{{infoList[node].AV}}]</div>
                    <div>Y[{{name[2]}}]</div>
                </div>
          </div>
        </div>
      </div>
         <div class="bot-condition-add flex">
            <!--  -->
                 <!-- <div>保存</div> -->
                 <!-- <div>载入</div> -->
                   <div class="condition-time flex">
               <div @click="serchHis(1)">1h</div>
               <div @click="serchHis(2)">2h</div>
               <div @click="serchHis(4)">4h</div>
               <div @click="serchHis(8)">8h</div>
               <div @click="serchHis(24)">24h</div>
               <div @click="serchHis(72)">72h</div>
             </div>
             </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    </div>
</template>
<script>
import inputVal from "@/components/inputVal.vue"   //输入框组件
import { getEchars } from "@/api/index/index"
export default {
   name: "Rsf",
   components: {inputVal},
    props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
    historyname: [String, Number], //先控名字区分
    node:[String, Number], //节点名
    titname:[String, Number], //tit名字
  },
    data: () => {
		return {
      name:[],
      mark:[],
      nodeList:[],
      xAxis:[],
      ydata:[],
      echartData:[],
      legendData:[],
      loading: true,
      timer:null,
      myChart:'',
      end_time:'',
      start_time:'',
      projactNanme:'',
      echartcolor:[],
      option:'',
		}
        
	},
       watch: {
    infoList: {
      handler (n, o) {
       this.infoList = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
        historyname: {
      handler (n, o) {
       this.historyname = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
      node: {
      handler (n, o) {
       this.node = n
       this.getInfo()
      },
      deep: true // 深度监听父组件传过来对象变化
    },
     option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.loading = false;
            this.myChart.setOption(newVal, true);
          } else {
            this.loading = false;
            this.myChart.setOption(oldVal);
          }
        } else {
          this.loading = false;
          this.drawLine();
        }
      },
    },
   },
    mounted() {

    this.getInfo()
        this.timer = setInterval(() => {
       setTimeout(this.getInfo(), 0)
        }, 1000*30000)
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {           
                clearInterval(this.timer);                                    
            })
  },
  created(){
     let projectData = JSON.parse(localStorage.getItem("deviceType"));
     this.projactNanme = projectData.device;
     this.mark = ['SP','PV','AV']
     this.name = [this.historyname +'_SP_WF',this.historyname +'_PV_WF',this.historyname +'_AV_WF']
     this.nodeList = [this.node,this.node,this.node]
      this.echartcolor = [
            "#1799cd",
            "#0de0ac",
            "#ec163e",
          ];
            this.ydata = [
            {
                type: "value",
                name:'',
                position: "left",
                boundaryGap:[0,'100%'],
                scale:true,
                axisLine: {
                    show:true,
                    lineStyle: {
                        color: this.echartcolor[0]
                    }
                },
                axisTick:{
                  show:true
                },
                axisLabel: {
                  formatter: "{value}",
                },
              },
              {
                type: "value",
                name: "",
                position: "left",
                offset:50,
                scale:true,
                axisTick:{
                  show:true
                },
                axisLine: {
                    show:true,
                    lineStyle: {
                        color: this.echartcolor[1]
                    }
                },
                axisLabel: {
                    
                  formatter: "{value}",
                },  
              },
              {
                type: "value",
                name: "",
                position: "right",
                scale:true,
                axisTick:{
                  show:true
                },
                axisLine: {
                    show:true,
                    lineStyle: {
                        color: this.echartcolor[2]
                    }
                },
                axisLabel: {
                    
                  formatter: "{value}",
                },  
              }]
  },
       methods:{
          //  查看过去趋势
        serchHis(val){
          this.end_time = this.$util.formatDate('yyyy-MM-dd mm:hh:ss')
          this.start_time = this.$util.getTimeData(val)
          this.getInfo()
        },
                  //    保存折线图片
        downloadImpByChart(){
            var url = this.myChart.getConnectedDataURL({
                    pixelRatio: 5,　　//导出的图片分辨率比率,默认是1
                    backgroundColor: 'dark',　　//图表背景色
                    excludeComponents:[　　//保存图表时忽略的工具组件,默认忽略工具栏
                    'toolbox'
                    ],
                    type:'png'　　//图片类型支持png和jpeg
                    });
                    var $a = document.createElement('a');
                    var type = 'png';
                    $a.download = this.myChart.getOption().title[0].text + '.' + type;
                    $a.target = '_blank';
                    $a.href = url;
                    // Chrome and Firefox
                    if (typeof MouseEvent === 'function') {
                    var evt = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                    });
                    $a.dispatchEvent(evt);
                    }
                    // IE
                    else {
                    var html = ''

                    '<body style="margin:0;">'
                    '![](' + url + ')'
                    '</body>';
                    var tab = window.open();
                    tab.document.write(html);
                    }
                            },
          // 获取折线图数据
           getInfo(){
                this.echartData = []
                this.xAxis = []
           let projectData = JSON.parse(localStorage.getItem("deviceType"))
            let params 
           if(this.start_time){
             params = {
                device:projectData.device,
                    mark:this.mark,
                    name:this.name,
                    node:this.nodeList,
                    end_time:this.end_time,
                start_time:this.start_time,
                cp_type: projectData.type,
           }
           }else{
                params = {
                device:projectData.device,
                    mark:this.mark,
                    name:this.name,
                    node:this.nodeList,
                    cp_type: projectData.type,
           }
           }
          getEchars(params)
            .then(res => {
            this.loading = false;
            this.xAxis = res.data.x;
            this.echartData = res.data.series;
             this.option = {
              title: {
                text: "",
              },
         tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            appendToBody:'html',
            className: 'echarts-tooltip echarts-tooltip-dark',
            formatter:(params) =>{
                this.rightarr = params
                }
                },
              color: this.echartcolor,
              legend: {
                data: this.echartData.length == 1 ? this.name1 : this.name,
              },
              grid: {
                left: "10%",
                right: "13%",
                bottom: "10%",
                containLabel: true,
              },
              toolbox: {
                 feature: {
                dataZoom: {
                },
                dataView: {
                  readOnly: false
                },
               
                restore: {},
                saveAsImage: {}
              },
              top:20
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                 axisTick: {
                     alignWithLabel: true
                 },
                  axisLine: {
                    onZero: false,
                    lineStyle: {
                    }
                  },
 
                data: this.xAxis,
              },
              yAxis: this.ydata,
              series: this.echartData,
              dataZoom: [
                {
                    //默认为滚动条型数据区域缩放组件
                  show: true,
                  xAxisIndex: [0],
                  start: 100,
                  end: 0,
                  // 开始位置的数值
                  startValue: 100,
                  // 结束位置的数值
                  endValue: 0,
                }
              ],
            };
            })
            .catch(err => {
              this.loading = false;
            //   this.$message.error('数据获取失败');
				})
    },
        // 曲线图
    drawLine() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(
          document.getElementById("rts_Chart2"),
          "dark"
        );
        this.myChart = myChart;
        // 绘制图表
        this.myChart.setOption(this.option);
        this.myChart.on('click',function(params){
        })
      },
        //    打开输入框组件。
        toIpt(data,name,historyname,node){
            this.$refs.inputVal.open(data,name,historyname,node)
        },
        closeCompon(){
           this.$emit('sendStatus', 'Rsf',false)
        },
    } 
}
</script>
<style lang="scss" scoped>
 .Rsf{
     width: 1256px;
     height: 888px;
     background: #DFEDF5;
     cursor:move;
     font-family: ArialNarrow;
     border-radius: 5px;
      .evaluate-header{
         height: 50px;
         background: #5DCEF3;
         line-height: 50px;
         text-align: center;
         color: #0a4c62;
         font-size: 24px;
         position: relative;
         border-radius: 5px 5px 0 0;
         font-family: MicrosoftYaHei;
         .evaluate-header-icon{
         width: 32px;
         height: 32px;
         position: absolute;
         right: 10px;
         top: 8px;
         border-radius: 32px;
         border: 1px solid #041A28;
      color: #041A28;
         line-height: 32px;
        cursor: pointer;
     }
     }
     .Observer-box{
       margin: 10px;
     }
     .Observer-box-tit{
        width: 200px;
        height: 32px;
        background-color: #d4e3ec;
        border-radius: 2px;
        font-family: MicrosoftYaHei;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #22465b;
          text-align: center;
          line-height: 32px;
     }
     .left-list{
       padding-top: 20px;
       width: 128px;
       .list-flex1 {
         	font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #22465b;
         .list-flex1-text{
           width: 44px;
           text-align: right;
         }
         .list-flex1-num{
            width: 80px;
            height: 32px;
            background-color: rgba(158,162,164,.1);
            border-radius: 2px;
            text-align: center;
            line-height: 32px;
            margin-left:4px;
             cursor: pointer;
         }
       }
     }
     .left-list1{
       .list-flex1-num{
         cursor: pointer;
       }
     }
     .bserver-box-flex-right{
       #rts_Chart2{
         width: 832px;
         height: 664px;
         padding-left: 30px;
         margin-top: 10px;
       }
         .rts_Chart-right{
           margin-left: auto;
           .lineCh{
               width: 188px;
               height: 215px;
               background: rgba(158,162,164,.1);
               margin: 0 auto;
               margin-top: 10px;
               margin-left: 20px;
              
               div{
                 font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #22465b;
                 padding: 0 10px;
               }
               
           } 
           .lineCh2{
             div{
               color: #0de0ac;
             }
           }
            .lineCh3{
             div{
               color: #ec163e;
             }
           }
        } 
     }
       .bot-condition-add{
      position: absolute;
      bottom: 20px;
      right: 10px;
         .condition-time{
      margin-left: auto;
       div{
           width: 40px;
           height: 30px;
           background-color: #d4e3ec;
	       border-radius: 2px;
           line-height: 30px;
           text-align: center;
           font-size: 20px;
           color: #94acba;
	       text-shadow: 0px 1px 0px 0px #ffffff;
           margin-right: 3px;
           cursor: pointer;
     }
    }
  }
 }
</style>