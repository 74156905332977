<template>
	<div>
		<div class="mflindex">
			<div class="luhao">
				<div class="fl luhao-row1">A煤斗</div>
				<div class="fl luhao-row2">B煤斗</div>
				<div class="fl luhao-row2">C煤斗</div>
				<div class="fl luhao-row2">D煤斗</div>
				<div class="fl luhao-row2">E煤斗</div>

			</div>
			<!-- div -->
			<div class="main-top">
				<div class="mainleft fl">
					<div class="mainleft-row1">
						<div class="fl">
							<div>
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__GML_A_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__GML_A_QK1
              ? toDetail(
                  2,
                  'GMLA_QK1',
                  'RQM__p__GML_A_QK1',
                  '',
                  '给煤量前馈'
                )
              : ''
          ">
									Q
								</div>
							</div>
							<div>
								<div class="bottonx fl" :style="{
            background: !infoList.XK__p__GMJ_XK1.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__GMJ_XK1
              ? toDetail(
                  1,
                  'GMJ_XK1',
                  'XK__p__GMJ_XK1',
                  '',
                  'A给煤机先控调节'
                )
              : ''
          ">X</div>
								<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN1.RM == 1  || infoList.RQM__p__MFL_MAN1.RM == 1 || infoList.RQM__p__CKT_MAN1.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANAM',3,'A','1')">A</div>
							</div>
						</div>
						<div class="fl row1-span">
							<div>
								<div class="valuex fl color1 " @click="toCompon(2, 'GML_A_SP', 'IOG', 'GML_A_SP_BM')"
									@dblclick="Cclick(infoList.IOG.GML_A_SP,'GML_A_SP', 'IOG', 'GML_A_SP_BM')">
									{{ infoList.IOG.GML_A_SP }}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div>
								<div class="valuex fl" @click="toCompon(2, 'GML_A_PV', 'IOG', 'GML_A_PV_BM','1#给煤量过程值')"
									@dblclick="Cclick(infoList.IOG.GML_A_PV,'GML_A_PV', 'IOG', 'GML_A_PV_BM')">
									{{infoList.IOG.GML_A_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
					</div>
					<div class="mainleft-row2">密封风</div>
					<div class="mainleft-row3">
						<div class="widthx">温度SP</div>
						<div class="widthx">温度PV</div>
					</div>
					<div class="mainleft-row2 mainleft-jg1">密封风</div>
					<div class="fengl">
						<div class="mainleft-row4 fl">
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量SP</div>
								<div class="valuex fl color1"
									@click="toCompon(2, 'MFL_A_DSP', 'IOG', 'MFL_A_DSP_BM','1#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_A_DSP,'MFL_A_DSP', 'IOG', 'MFL_A_DSP_BM')">
									{{ infoList.IOG.MFL_A_DSP }}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量PV</div>
								<div class="valuex fl"
									@click="toCompon(2, 'MFL_A_PV', 'IOG', 'MFL_A_PV_BM','1#磨出口风量PV')"
									@dblclick="Cclick(infoList.IOG.MFL_A_PV,'MFL_A_PV', 'IOG', 'MFL_A_PV_BM')">
									{{infoList.IOG.MFL_A_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
						<div class="fl mainleft-row4-1">
							<div class="widthx1">油压SP</div>
							<div class="widthx1">油压PV</div>
						</div>
					</div>
					<div class="flbutton">
						<div class="buttonfl buttonjg fl" @click="toCompon(6,'A','MFL_','风量','FM')">风量SP</div>
						<div class="buttonfl buttonjgx fl" @click="toCompon(5,'A','1')">磨煤机</div>
						<div class="buttonfl buttonjg1 fl " @click="toCompon(6,'A','MYP_','油压','YM')">油压SP</div>
					</div>
					<div class="mainleft-row6">
						<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MYP_XK1.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MYP_XK1
              ? toDetail(
                  1,
                  'MYP_XK1',
                  'XK__p__MYP_XK1',
                  '',
                  'A磨油压先控调节'
                )
              : ''
          ">X</div>
						<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__MYP_MAN1.RM==1  || infoList.RQM__p__MYP_MAN2.RM==1 || infoList.RQM__p__MYP_MAN3.RM == 1 || infoList.RQM__p__MYP_MAN4.RM == 1 || infoList.RQM__p__MYP_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANMYP',5)">A</div>
					</div>
					<div class="mainleft-row7">
						<div class="fl mainleft-row7-span1">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'RFM_A_FV', 'IOG', 'RFM_A_FV_BM','1#磨热风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.RFM_A_FV,'RFM_A_FV', 'IOG', 'RFM_A_FV_BM')">
									{{infoList.IOG.RFM_A_FV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx " style="margin-left:1vw;" :style="{
            background: !infoList.RQM__p__MFL_A_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__MFL_A_QK1
              ? toDetail(
                  2,
                  'MFLA_QK1',
                  'RQM__p__MFL_A_QK1',
                  '',
                  '磨风量前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" style="margin-left:1vw;" :style="{
            background: !infoList.XK__p__MFL_XK1.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MFL_XK1
              ? toDetail(
                  1,
                  'MFL_XK1',
                  'XK__p__MFL_XK1',
                  '',
                  'A磨风量先控调节'
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN1.RM == 1  || infoList.RQM__p__MFL_MAN1.RM == 1 || infoList.RQM__p__CKT_MAN1.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANAM',3,'A','1')">A</div>
								</div>
							</div>
						</div>
						<div class="fl mainleft-row7-span2">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'LFM_A_FV', 'IOG', 'LFM_A_FV_BM','1#磨冷风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.LFM_A_FV,'LFM_A_FV', 'IOG', 'LFM_A_FV_BM')">
									{{infoList.IOG.LFM_A_FV}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__CKT_A_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__CKT_A_QK1
              ? toDetail(
                  2,
                  'CKTA_QK1',
                  'RQM__p__CKT_A_QK1',
                  '',
                  '磨温度前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__CKT_XK1.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__CKT_XK1
              ? toDetail(
                  1,
                  'CKT_XK1',
                  'XK__p__CKT_XK1',
                  '',
                  'A磨出口温度先控调节'
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN1.RM == 1  || infoList.RQM__p__MFL_MAN1.RM == 1 || infoList.RQM__p__CKT_MAN1.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANAM',3,'A','1')">A</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fl mainleft-jg">
					<div class="mainleft-jg-row1">
						<div class="valuexx fl color1 "
							@click="toCompon(2, 'CKT_A_SP', 'IOG', 'CKT_A_SP_BM','1#磨出口温度SP')"
							@dblclick="Cclick(infoList.IOG.CKT_A_SP,'CKT_A_SP', 'IOG', 'CKT_A_SP_BM')">
							{{ infoList.IOG.CKT_A_SP }}&nbsp;℃
						</div>
					</div>
					<div>
						<div class="valuexx fl color" @click="toCompon(2, 'CKT_A_PV', 'IOG', 'CKT_A_PV_BM','1#磨出口温度PV')"
							@dblclick="Cclick(infoList.IOG.CKT_A_PV,'CKT_A_PV', 'IOG', 'CKT_A_PV_BM')">
							{{infoList.IOG.CKT_A_PV}}
							&nbsp;℃</div>
					</div>
					<div class="valuexx color1 mainleft-jg-row1-jg"
						@click="toCompon(2, 'MYP_A_DSP', 'IOG', 'MYP_A_DSP_BM','A磨油压SP')"
						@dblclick="Cclick(infoList.IOG.MYP_A_DSP,'MYP_A_DSP', 'IOG', 'MYP_A_DSP_BM')">
						{{ infoList.IOG.MYP_A_DSP }} &nbsp;MPa
					</div>
					<div class="valuexx color" @click="toCompon(2, 'MYP_A_PV', 'IOG', 'MYP_A_PV_BM','A磨油压PV')"
						@dblclick="Cclick(infoList.IOG.MYP_A_PV,'MYP_A_PV', 'IOG', 'MYP_A_PV_BM')">
						{{infoList.IOG.MYP_A_PV}}
						&nbsp;MPa</div>
				</div>
				<div class="mainleft fl">
					<div class="mainleft-row1">
						<div class="fl">
							<div>
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__GML_B_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__GML_B_QK1
              ? toDetail(
                  2,
                  'GMLB_QK1',
                  'RQM__p__GML_B_QK1',
                  '',
                  '给煤量前馈'
                )
              : ''
          ">
									Q
								</div>
							</div>
							<div>
								<div class="bottonx fl" :style="{
            background: !infoList.XK__p__GMJ_XK2.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__GMJ_XK2
              ? toDetail(
                  1,
                  'GMJ_XK2',
                  'XK__p__GMJ_XK2',
                  '',
                  'B给煤机先控调节'
                )
              : ''
          ">X</div>
								<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN2.RM==1  || infoList.RQM__p__MFL_MAN2.RM==1 || infoList.RQM__p__CKT_MAN2.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANBM',3,'B','2')">A</div>
							</div>
						</div>
						<div class="fl row1-span">
							<div>
								<div class="valuex fl color1 "
									@click="toCompon(2, 'GML_B_SP', 'IOG', 'GML_B_SP_BM','2#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_B_SP,'GML_B_SP', 'IOG', 'GML_B_SP_BM')">
									{{infoList.IOG.GML_B_SP}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div>
								<div class="valuex fl" @click="toCompon(2, 'GML_B_PV', 'IOG', 'GML_B_PV_BM','2#给煤量过程值')"
									@dblclick="Cclick(infoList.IOG.GML_B_PV,'GML_B_PV', 'IOG', 'GML_B_PV_BM')">
									{{infoList.IOG.GML_B_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
					</div>
					<div class="mainleft-row2">密封风</div>
					<div class="mainleft-row3">
						<div class="widthx">温度SP</div>
						<div class="widthx">温度PV</div>
					</div>
					<div class="mainleft-row2 mainleft-jg1">密封风</div>
					<div class="fengl">
						<div class="mainleft-row4 fl">
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量SP</div>
								<div class="valuex fl color1"
									@click="toCompon(2, 'MFL_B_DSP', 'IOG', 'MFL_B_DSP_BM','2#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_B_DSP,'MFL_B_DSP', 'IOG', 'MFL_B_DSP_BM')">
									{{ infoList.IOG.MFL_B_DSP }}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量PV</div>
								<div class="valuex fl"
									@click="toCompon(2, 'MFL_B_PV', 'IOG', 'MFL_B_PV_BM','2#磨出口风量PV')"
									@dblclick="Cclick(infoList.IOG.MFL_B_PV,'MFL_B_PV', 'IOG', 'MFL_B_PV_BM')">
									{{infoList.IOG.MFL_B_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
						<div class="fl mainleft-row4-1">
							<div class="widthx1">油压SP</div>
							<div class="widthx1">油压PV</div>
						</div>
					</div>
					<div class="flbutton">
						<div class="buttonfl buttonjg fl" @click="toCompon(6,'B','MFL_','风量','FM')">风量SP</div>
						<div class="buttonfl buttonjgx fl" @click="toCompon(5,'B','2')">磨煤机</div>
						<div class="buttonfl buttonjg1 fl " @click="toCompon(6,'B','MYP_','油压','YM')">油压SP</div>
					</div>
					<div class="mainleft-row6">
						<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MYP_XK2.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MYP_XK2
              ? toDetail(
                  1,
                  'MYP_XK2',
                  'XK__p__MYP_XK2',
                  '',
                  'B磨油压先控调节 '
                )
              : ''
          ">X</div>
						<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__MYP_MAN1.RM==1  || infoList.RQM__p__MYP_MAN2.RM==1 || infoList.RQM__p__MYP_MAN3.RM == 1 || infoList.RQM__p__MYP_MAN4.RM == 1 || infoList.RQM__p__MYP_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANMYP',5)">A</div>
					</div>
					<div class="mainleft-row7">
						<div class="fl mainleft-row7-span1">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'RFM_B_FV', 'IOG', 'RFM_B_FV_BM','2#磨热风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.RFM_B_FV,'RFM_B_FV', 'IOG', 'RFM_B_FV_BM')">
									{{infoList.IOG.RFM_B_FV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx " :style="{
            background: !infoList.RQM__p__MFL_B_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__MFL_B_QK1
              ? toDetail(
                  2,
                  'MFLB_QK1',
                  'RQM__p__MFL_B_QK1',
                  '',
                  'B磨风量前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MFL_XK2.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MFL_XK2
              ? toDetail(
                  1,
                  'MFL_XK2',
                  'XK__p__MFL_XK2',
                  '',
                  'B磨风量先控调节'
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN2.RM==1  || infoList.RQM__p__MFL_MAN2.RM==1 || infoList.RQM__p__CKT_MAN2.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANBM',3,'B','2')">A</div>
								</div>
							</div>
						</div>
						<div class="fl mainleft-row7-span2">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'LFM_B_FV', 'IOG', 'LFM_B_FV_BM','2#磨冷风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.LFM_B_FV,'LFM_B_FV', 'IOG', 'LFM_B_FV_BM')">
									{{infoList.IOG.LFM_B_FV}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__CKT_B_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__CKT_B_QK1
              ? toDetail(
                  2,
                  'CKTB_QK1',
                  'RQM__p__CKT_B_QK1',
                  '',
                  'B磨温度前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__CKT_XK2.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__CKT_XK2
              ? toDetail(
                  1,
                  'CKT_XK2',
                  'XK__p__CKT_XK2',
                  '',
                  'B磨出口温度先控调节'
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN2.RM==1  || infoList.RQM__p__MFL_MAN2.RM==1 || infoList.RQM__p__CKT_MAN2.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANBM',3,'B','2')">A</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fl mainleft-jg">
					<div class="mainleft-jg-row1">
						<div class="valuexx fl  color1"
							@click="toCompon(2, 'CKT_B_SP', 'IOG', 'CKT_B_SP_BM','2#磨出口温度SP')"
							@dblclick="Cclick(infoList.IOG.CKT_B_SP,'CKT_B_SP', 'IOG', 'CKT_B_SP_BM')">
							{{ infoList.IOG.CKT_B_SP }}&nbsp;℃
						</div>
					</div>
					<div>
						<div class="valuexx fl color" @click="toCompon(2, 'CKT_B_PV', 'IOG', 'CKT_B_PV_BM','2#磨出口温度PV')"
							@dblclick="Cclick(infoList.IOG.CKT_B_PV,'CKT_B_PV', 'IOG', 'CKT_B_PV_BM')">
							{{infoList.IOG.CKT_B_PV}}
							&nbsp;℃</div>
					</div>
					<div class="valuexx color1 mainleft-jg-row1-jg"
						@click="toCompon(2, 'MYP_B_DSP', 'IOG', 'MYP_B_DSP_BM','B磨油压SP')"
						@dblclick="Cclick(infoList.IOG.MYP_B_DSP,'MYP_B_DSP', 'IOG', 'MYP_B_DSP_BM')">
						{{ infoList.IOG.MYP_B_DSP }} &nbsp;MPa
					</div>
					<div class="valuexx color" @click="toCompon(2, 'MYP_B_PV', 'IOG', 'MYP_B_PV_BM','B磨油压PV')"
						@dblclick="Cclick(infoList.IOG.MYP_B_PV,'MYP_B_PV', 'IOG', 'MYP_B_PV_BM')">
						{{infoList.IOG.MYP_B_PV}}
						&nbsp;MPa</div>
				</div>
				<div class="mainleft fl">
					<div class="mainleft-row1">
						<div class="fl">
							<div>
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__GML_C_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__GML_C_QK1
              ? toDetail(
                  2,
                  'GMLC_QK1',
                  'RQM__p__GML_C_QK1',
                  '',
                  'C给煤量前馈'
                )
              : ''
          ">
									Q
								</div>
							</div>
							<div>
								<div class="bottonx fl" :style="{
            background: !infoList.XK__p__GMJ_XK3.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__GMJ_XK3
              ? toDetail(
                  1,
                  'GMJ_XK3',
                  'XK__p__GMJ_XK3',
                  '',
                  '给煤机先控调节'
                )
              : ''
          ">X</div>
								<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN3.RM==1  || infoList.RQM__p__MFL_MAN3.RM==1 || infoList.RQM__p__CKT_MAN3.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANCM',3,'C','3')">A</div>
							</div>
						</div>
						<div class="fl row1-span">
							<div>
								<div class="valuex fl color1 "
									@click="toCompon(2, 'GML_C_SP', 'IOG', 'GML_C_SP_BM','3#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_C_SP,'GML_C_SP', 'IOG', 'GML_C_SP_BM')">
									{{infoList.IOG.GML_C_SP}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div>
								<div class="valuex fl" @click="toCompon(2, 'GML_C_PV', 'IOG', 'GML_C_PV_BM','3#给煤量过程值')"
									@dblclick="Cclick(infoList.IOG.GML_C_PV,'GML_C_PV', 'IOG', 'GML_C_PV_BM')">
									{{infoList.IOG.GML_C_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
					</div>
					<div class="mainleft-row2">密封风</div>
					<div class="mainleft-row3">
						<div class="widthx">温度SP</div>
						<div class="widthx">温度PV</div>
					</div>
					<div class="mainleft-row2 mainleft-jg1">密封风</div>
					<div class="fengl">
						<div class="mainleft-row4 fl">
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量SP</div>
								<div class="valuex fl color1"
									@click="toCompon(2, 'MFL_C_DSP', 'IOG', 'MFL_C_DSP_BM','3#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_C_DSP,'MFL_C_DSP', 'IOG', 'MFL_C_DSP_BM')">
									{{ infoList.IOG.MFL_C_DSP }}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量PV</div>
								<div class="valuex fl"
									@click="toCompon(2, 'MFL_C_PV', 'IOG', 'MFL_C_PV_BM','3#磨出口风量PV')"
									@dblclick="Cclick(infoList.IOG.MFL_C_PV,'MFL_C_PV', 'IOG', 'MFL_C_PV_BM')">
									{{infoList.IOG.MFL_C_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
						<div class="fl mainleft-row4-1">
							<div class="widthx1">油压SP</div>
							<div class="widthx1">油压PV</div>
						</div>
					</div>
					<div class="flbutton">
						<div class="buttonfl buttonjg fl" @click="toCompon(6,'C','MFL_','风量','FM')">风量SP</div>
						<div class="buttonfl buttonjgx fl" @click="toCompon(5,'C','3')">磨煤机</div>
						<div class="buttonfl buttonjg1 fl " @click="toCompon(6,'C','MYP_','油压','YM')">油压SP</div>
					</div>
					<div class="mainleft-row6">
						<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MYP_XK3.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MYP_XK3
              ? toDetail(
                  1,
                  'MYP_XK3',
                  'XK__p__MYP_XK3',
                  '',
                  'C磨油压先控调节 '
                )
              : ''
          ">X</div>
						<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__MYP_MAN1.RM==1  || infoList.RQM__p__MYP_MAN2.RM==1 || infoList.RQM__p__MYP_MAN3.RM == 1 || infoList.RQM__p__MYP_MAN4.RM == 1 || infoList.RQM__p__MYP_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANMYP',5)">A</div>
					</div>
					<div class="mainleft-row7">
						<div class="fl mainleft-row7-span1">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'RFM_C_FV', 'IOG', 'RFM_C_FV_BM','3#磨热风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.RFM_C_FV,'RFM_C_FV', 'IOG', 'RFM_C_FV_BM')">
									{{infoList.IOG.RFM_C_FV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx " :style="{
            background: !infoList.RQM__p__MFL_C_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__MFL_C_QK1
              ? toDetail(
                  2,
                  'MFLC_QK1',
                  'RQM__p__MFL_C_QK1',
                  '',
                  'C磨风量前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MFL_XK3.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MFL_XK3
              ? toDetail(
                  1,
                  'MFL_XK3',
                  'XK__p__MFL_XK3',
                  '',
                  'C磨风量先控调节'
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN3.RM==1  || infoList.RQM__p__MFL_MAN3.RM==1 || infoList.RQM__p__CKT_MAN3.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANCM',3,'C','3')">A</div>
								</div>
							</div>
						</div>
						<div class="fl mainleft-row7-span2">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'LFM_C_FV', 'IOG', 'LFM_C_FV_BM','3#磨冷风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.LFM_C_FV,'LFM_C_FV', 'IOG', 'LFM_C_FV_BM')">
									{{infoList.IOG.LFM_C_FV}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__CKT_C_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__CKT_C_QK1
              ? toDetail(
                  2,
                  'CKTC_QK1',
                  'RQM__p__CKT_C_QK1',
                  '',
                  'C磨温度前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__CKT_XK3.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__CKT_XK3
              ? toDetail(
                  1,
                  'CKT_XK3',
                  'XK__p__CKT_XK3',
                  '',
                  'C磨出口温度先控调节 '
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN3.RM==1  || infoList.RQM__p__MFL_MAN3.RM==1 || infoList.RQM__p__CKT_MAN3.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANCM',3,'C','3')">A</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fl mainleft-jg">
					<div class="mainleft-jg-row1">
						<div class="valuexx fl color1"
							@click="toCompon(2, 'CKT_C_SP', 'IOG', 'CKT_C_SP_BM','3#磨出口温度SP')"
							@dblclick="Cclick(infoList.IOG.CKT_C_SP,'CKT_C_SP', 'IOG', 'CKT_C_SP_BM')">
							{{ infoList.IOG.CKT_C_SP }}&nbsp;℃
						</div>
					</div>
					<div>
						<div class="valuexx fl color" @click="toCompon(2, 'CKT_C_PV', 'IOG', 'CKT_C_PV_BM','3#磨出口温度PV')"
							@dblclick="Cclick(infoList.IOG.CKT_C_PV,'CKT_C_PV', 'IOG', 'CKT_C_PV_BM')">
							{{infoList.IOG.CKT_C_PV}}
							&nbsp;℃</div>
					</div>
					<div class="valuexx color1 mainleft-jg-row1-jg"
						@click="toCompon(2, 'MYP_C_DSP', 'IOG', 'MYP_C_DSP_BM','C磨油压SP')"
						@dblclick="Cclick(infoList.IOG.MYP_C_DSP,'MYP_C_DSP', 'IOG', 'MYP_C_DSP_BM')">
						{{ infoList.IOG.MYP_C_DSP }} &nbsp;MPa
					</div>
					<div class="valuexx color" @click="toCompon(2, 'MYP_C_PV', 'IOG', 'MYP_C_PV_BM','C磨油压PV')"
						@dblclick="Cclick(infoList.IOG.MYP_C_PV,'MYP_C_PV', 'IOG', 'MYP_C_PV_BM')">
						{{infoList.IOG.MYP_C_PV}}
						&nbsp;MPa</div>
				</div>
				<div class="mainleft fl">
					<div class="mainleft-row1">
						<div class="fl">
							<div>
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__GML_D_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__GML_D_QK1
              ? toDetail(
                  2,
                  'GMLA_QK1',
                  'RQM__p__GML_D_QK1',
                  '',
                  'D给煤量前馈'
                )
              : ''
          ">
									Q
								</div>
							</div>
							<div>
								<div class="bottonx fl" :style="{
            background: !infoList.XK__p__GMJ_XK4.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__GMJ_XK4
              ? toDetail(
                  1,
                  'GMJ_XK4',
                  'XK__p__GMJ_XK4',
                  '',
                  'D给煤机先控调节'
                )
              : ''
          ">X</div>
								<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN4.RM==1  || infoList.RQM__p__MFL_MAN4.RM==1 || infoList.RQM__p__CKT_MAN4.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANDM',3,'D','4')">A</div>
							</div>
						</div>
						<div class="fl row1-span">
							<div>
								<div class="valuex fl color1 "
									@click="toCompon(2, 'GML_D_SP', 'IOG', 'GML_D_SP_BM','4#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_D_SP,'GML_D_SP', 'IOG', 'GML_D_SP_BM')">
									{{infoList.IOG.GML_D_SP}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div>
								<div class="valuex fl" @click="toCompon(2, 'GML_D_PV', 'IOG', 'GML_D_PV_BM','4#给煤量过程值')"
									@dblclick="Cclick(infoList.IOG.GML_D_PV,'GML_D_PV', 'IOG', 'GML_D_PV_BM')">
									{{infoList.IOG.GML_D_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
					</div>
					<div class="mainleft-row2">密封风</div>
					<div class="mainleft-row3">
						<div class="widthx">温度SP</div>
						<div class="widthx">温度PV</div>
					</div>
					<div class="mainleft-row2 mainleft-jg1">密封风</div>
					<div class="fengl">
						<div class="mainleft-row4 fl">
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量SP</div>
								<div class="valuex fl color1"
									@click="toCompon(2, 'MFL_D_DSP', 'IOG', 'MFL_D_DSP_BM','4#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_D_DSP,'MFL_D_DSP', 'IOG', 'MFL_D_DSP_BM')">
									{{ infoList.IOG.MFL_D_DSP }}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量PV</div>
								<div class="valuex fl"
									@click="toCompon(2, 'MFL_D_PV', 'IOG', 'MFL_D_PV_BM','4#磨出口风量PV')"
									@dblclick="Cclick(infoList.IOG.MFL_D_PV,'MFL_D_PV', 'IOG', 'MFL_D_PV_BM')">
									{{infoList.IOG.MFL_D_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
						<div class="fl mainleft-row4-1">
							<div class="widthx1">油压SP</div>
							<div class="widthx1">油压PV</div>
						</div>
					</div>
					<div class="flbutton">
						<div class="buttonfl buttonjg fl" @click="toCompon(6,'D','MFL_','风量','FM')">风量SP</div>
						<div class="buttonfl buttonjgx fl" @click="toCompon(5,'D','4')">磨煤机</div>
						<div class="buttonfl buttonjg1 fl " @click="toCompon(6,'D','MYP_','油压','YM')">油压SP</div>
					</div>
					<div class="mainleft-row6">
						<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MYP_XK4.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MYP_XK4
              ? toDetail(
                  1,
                  'MYP_XK4',
                  'XK__p__MYP_XK4',
                  '',
                  '磨油压先控调节'
                )
              : ''
          ">X</div>
						<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__MYP_MAN1.RM==1  || infoList.RQM__p__MYP_MAN2.RM==1 || infoList.RQM__p__MYP_MAN3.RM == 1 || infoList.RQM__p__MYP_MAN4.RM == 1 || infoList.RQM__p__MYP_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANMYP',5)">A</div>
					</div>
					<div class="mainleft-row7">
						<div class="fl mainleft-row7-span1">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'RFM_D_FV', 'IOG', 'RFM_D_FV_BM','4#磨热风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.RFM_D_FV,'RFM_D_FV', 'IOG', 'RFM_D_FV_BM')">
									{{infoList.IOG.RFM_D_FV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx " :style="{
            background: !infoList.RQM__p__MFL_D_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__MFL_D_QK1
              ? toDetail(
                  2,
                  'MFLD_QK1',
                  'RQM__p__MFL_D_QK1',
                  '',
                  'D磨风量前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MFL_XK4.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MFL_XK4
              ? toDetail(
                  1,
                  'MFL_XK4',
                  'XK__p__MFL_XK4',
                  '',
                  'D磨风量先控调节'
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN4.RM==1  || infoList.RQM__p__MFL_MAN4.RM==1 || infoList.RQM__p__CKT_MAN4.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANDM',3,'D','4')">A</div>
								</div>
							</div>
						</div>
						<div class="fl mainleft-row7-span2">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'LFM_D_FV', 'IOG', 'LFM_D_FV_BM','4#磨冷风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.LFM_D_FV,'LFM_D_FV', 'IOG', 'LFM_D_FV_BM')">
									{{infoList.IOG.LFM_D_FV}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__CKT_D_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__CKT_D_QK1
              ? toDetail(
                  2,
                  'CKTA_QK1',
                  'RQM__p__CKT_D_QK1',
                  '',
                  'D磨温度前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__CKT_XK4.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__CKT_XK4
              ? toDetail(
                  1,
                  'CKT_XK4',
                  'XK__p__CKT_XK4',
                  '',
                  'D磨出口温度先控调节 '
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN4.RM==1  || infoList.RQM__p__MFL_MAN4.RM==1 || infoList.RQM__p__CKT_MAN4.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANDM',3,'D','4')">A</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fl mainleft-jg">
					<div class="mainleft-jg-row1">
						<div class="valuexx fl color1 "
							@click="toCompon(2, 'CKT_D_SP', 'IOG', 'CKT_D_SP_BM','4#磨出口温度SP')"
							@dblclick="Cclick(infoList.IOG.CKT_D_SP,'CKT_D_SP', 'IOG', 'CKT_D_SP_BM')">
							{{ infoList.IOG.CKT_D_SP }}&nbsp;℃
						</div>
					</div>
					<div>
						<div class="valuexx fl color" @click="toCompon(2, 'CKT_D_PV', 'IOG', 'CKT_D_PV_BM','4#磨出口温度PV')"
							@dblclick="Cclick(infoList.IOG.CKT_D_PV,'CKT_D_PV', 'IOG', 'CKT_D_PV_BM')">
							{{infoList.IOG.CKT_D_PV}}
							&nbsp;℃</div>
					</div>
					<div class="valuexx color1 mainleft-jg-row1-jg"
						@click="toCompon(2, 'MYP_D_DSP', 'IOG', 'MYP_D_DSP_BM','D磨油压SP')"
						@dblclick="Cclick(infoList.IOG.MYP_D_DSP,'MYP_D_DSP', 'IOG', 'MYP_D_DSP_BM')">
						{{ infoList.IOG.MYP_D_DSP }} &nbsp;MPa
					</div>
					<div class="valuexx color" @click="toCompon(2, 'MYP_D_PV', 'IOG', 'MYP_D_PV_BM','D磨油压PV')"
						@dblclick="Cclick(infoList.IOG.MYP_D_PV,'MYP_D_PV', 'IOG', 'MYP_D_PV_BM')">
						{{infoList.IOG.MYP_D_PV}}
						&nbsp;MPa</div>
				</div>
				<div class="mainleft fl">
					<div class="mainleft-row1">
						<div class="fl">
							<div>
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__GML_E_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__GML_E_QK1
              ? toDetail(
                  2,
                  'GMLA_QK1',
                  'RQM__p__GML_E_QK1',
                  '',
                  'E给煤量前馈'
                )
              : ''
          ">
									Q
								</div>
							</div>
							<div>
								<div class="bottonx fl" :style="{
            background: !infoList.XK__p__GMJ_XK5.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__GMJ_XK5
              ? toDetail(
                  1,
                  'GMJ_XK5',
                  'XK__p__GMJ_XK5',
                  '',
                  'E磨出口温度先控调节 '
                )
              : ''
          ">X</div>
								<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN5.RM==1  || infoList.RQM__p__MFL_MAN5.RM==1 || infoList.RQM__p__CKT_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANEM',3,'E','5')">A</div>
							</div>
						</div>
						<div class="fl row1-span">
							<div>
								<div class="valuex fl color1"
									@click="toCompon(2, 'GML_E_SP', 'IOG', 'GML_E_SP_BM','5#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_E_SP,'GML_E_SP', 'IOG', 'GML_E_SP_BM')">
									{{infoList.IOG.GML_E_SP}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div>
								<div class="valuex fl" @click="toCompon(2, 'GML_E_PV', 'IOG', 'GML_E_PV_BM','5#给煤量过程值')"
									@dblclick="Cclick(infoList.IOG.GML_E_PV,'GML_E_PV', 'IOG', 'GML_E_PV_BM')">
									{{infoList.IOG.GML_E_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
					</div>
					<div class="mainleft-row2">密封风</div>
					<div class="mainleft-row3">
						<div class="widthx">温度SP</div>
						<div class="widthx">温度PV</div>
					</div>
					<div class="mainleft-row2 mainleft-jg1">密封风</div>
					<div class="fengl">
						<div class="mainleft-row4 fl">
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量SP</div>
								<div class="valuex fl color1"
									@click="toCompon(2, 'MFL_E_DSP', 'IOG', 'MFL_E_DSP_BM','5#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_E_DSP,'MFL_E_DSP', 'IOG', 'MFL_E_DSP_BM')">
									{{ infoList.IOG.MFL_E_DSP }}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="mainleft-row4-span">
								<div class="widthx fl">风量PV</div>
								<div class="valuex fl"
									@click="toCompon(2, 'MFL_E_PV', 'IOG', 'MFL_E_PV_BM','5#磨出口风量PV')"
									@dblclick="Cclick(infoList.IOG.MFL_E_PV,'MFL_E_PV', 'IOG', 'MFL_E_PV_BM')">
									{{infoList.IOG.MFL_E_PV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
						</div>
						<div class="fl mainleft-row4-1">
							<div class="widthx1">油压SP</div>
							<div class="widthx1">油压PV</div>
						</div>
					</div>
					<div class="flbutton">
						<div class="buttonfl buttonjg fl" @click="toCompon(6,'E','MFL_','风量','FM')">风量SP</div>
						<div class="buttonfl buttonjgx fl" @click="toCompon(5,'E','5')">磨煤机</div>
						<div class="buttonfl buttonjg1 fl " @click="toCompon(6,'E','MYP_','油压','YM')">油压SP</div>
					</div>
					<div class="mainleft-row6">
						<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MYP_XK5.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MYP_XK5
              ? toDetail(
                  1,
                  'MYP_XK5',
                  'XK__p__MYP_XK5',
                  '',
                  'E磨油压先控调节 '
                )
              : ''
          ">X</div>
						<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__MYP_MAN1.RM==1  || infoList.RQM__p__MYP_MAN2.RM==1 || infoList.RQM__p__MYP_MAN3.RM == 1 || infoList.RQM__p__MYP_MAN4.RM == 1 || infoList.RQM__p__MYP_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANMYP',5)">A</div>
					</div>
					<div class="mainleft-row7">
						<div class="fl mainleft-row7-span1">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'RFM_E_FV', 'IOG', 'RFM_E_FV_BM','5#磨热风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.RFM_E_FV,'RFM_E_FV', 'IOG', 'RFM_E_FV_BM')">
									{{infoList.IOG.RFM_E_FV}}
								</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx " :style="{
            background: !infoList.RQM__p__MFL_A_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__MFL_E_QK1
              ? toDetail(
                  2,
                  'MFLE_QK1',
                  'RQM__p__MFL_E_QK1',
                  '',
                  'E磨风量前馈'
                )
              : ''
          ">
									Q
								</div>

								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__MFL_XK5.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__MFL_XK5
              ? toDetail(
                  1,
                  'MFL_XK5',
                  'XK__p__MFL_XK5',
                  '',
                  'E磨风量先控调节'
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN5.RM==1  || infoList.RQM__p__MFL_MAN5.RM==1 || infoList.RQM__p__CKT_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANEM',3,'E','5')">A</div>
								</div>
							</div>
						</div>
						<div class="fl mainleft-row7-span2">
							<div>
								<div class="valuex fl"
									@click="toCompon(2, 'LFM_E_FV', 'IOG', 'LFM_E_FV_BM','5#磨冷风门开度反馈')"
									@dblclick="Cclick(infoList.IOG.LFM_E_FV,'LFM_E_FV', 'IOG', 'LFM_E_FV_BM')">
									{{infoList.IOG.LFM_E_FV}}</div>
								<div class="danwei fl">t/h</div>
							</div>
							<div class="bottonx-jg">
								<div class="bottonx" :style="{
            background: !infoList.RQM__p__CKT_E_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__CKT_E_QK1
              ? toDetail(
                  2,
                  'CKTA_QK1',
                  'RQM__p__CKT_E_QK1',
                  '',
                  'E磨温度前馈'
                )
              : ''
          ">
									Q
								</div>
								<div>
									<div class="bottonx fl" :style="{
            background: !infoList.XK__p__CKT_XK5.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__CKT_XK5
              ? toDetail(
                  1,
                  'CKT_XK5',
                  'XK__p__CKT_XK5',
                  '',
                  'E磨出口温度先控调节 '
                )
              : ''
          ">X</div>
									<div class="bottonx fl" :style="{
						background:
						infoList.RQM__p__GMJ_MAN5.RM==1  || infoList.RQM__p__MFL_MAN5.RM==1 || infoList.RQM__p__CKT_MAN5.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'MFL_MANEM',3,'E','5')">A</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fl mainleft-jg">
					<div class="mainleft-jg-row1">
						<div class="valuexx fl color1"
							@click="toCompon(2, 'CKT_E_SP', 'IOG', 'CKT_E_SP_BM','5#磨出口温度SP')"
							@dblclick="Cclick(infoList.IOG.CKT_E_SP,'CKT_E_SP', 'IOG', 'CKT_E_SP_BM')">
							{{ infoList.IOG.CKT_E_SP }}&nbsp;℃
						</div>
					</div>
					<div>
						<div class="valuexx fl color" @click="toCompon(2, 'CKT_E_PV', 'IOG', 'CKT_E_PV_BM','5#磨出口温度PV')"
							@dblclick="Cclick(infoList.IOG.CKT_E_PV,'CKT_E_PV', 'IOG', 'CKT_E_PV_BM')">
							{{infoList.IOG.CKT_E_PV}}
							&nbsp;℃</div>
					</div>
					<div class="valuexx color1 mainleft-jg-row1-jg"
						@click="toCompon(2, 'MYP_E_DSP', 'IOG', 'MYP_E_DSP_BM','E磨油压SP')"
						@dblclick="Cclick(infoList.IOG.MYP_E_DSP,'MYP_E_DSP', 'IOG', 'MYP_E_DSP_BM')">
						{{ infoList.IOG.MYP_E_DSP }} &nbsp;MPa
					</div>
					<div class="valuexx color" @click="toCompon(2, 'MYP_E_PV', 'IOG', 'MYP_E_PV_BM','E磨油压PV')"
						@dblclick="Cclick(infoList.IOG.MYP_E_PV,'MYP_E_PV', 'IOG', 'MYP_E_PV_BM')">
						{{infoList.IOG.MYP_E_PV}}
						&nbsp;MPa</div>
				</div>



			</div>
			<div class="button-bottom button-bottom-jg fl">
				热一次风母管
			</div>
			<div class="button-bottom button-bottom-jg2 fl">
				冷一次风母管
			</div>
			<div class="button-bottom button-bottom-jg3 fl">
				密封风母管
			</div>
			<div class="main-bottom">

			</div>
		</div>

		<mflmanyManual v-if="mflmanyManual" :titname="Manualtitname" :numM="Manualnode" :mark="Manualmark"
			:manvar="Manualvar" @sendStatus="isClose" :infoList="infoList"></mflmanyManual>

		<Firstcontrol :infoList="infoList" :isIndex="isIndex" :historyname="Firstcontrolname"
			:titname="Firstcontroltitname" :node="Firstcontrolnode" v-if="isFshow" @sendStatus="isClose"></Firstcontrol>
		<Historical v-if="isHshow" @sendStatus="isClose" :historyname="historyname" :node="nodename" :Lkname="Aname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>

		<publicParam v-if="isPshow" @sendStatus="isClose" :infoList="infoList"></publicParam>

		<MfMAC v-if="MfMAC" @sendStatus='isClose' :infoList="infoList" :MfMACCS1="MfMACCS1" :MfMACCS2="MfMACCS2">
		</MfMAC>
		<MfFMAZX v-if="MfFMAZX" @sendStatus='isClose' :infoList="infoList" :MfFMAZXCS1="MfFMAZXCS1"
			:MfFMAZXCS2="MfFMAZXCS2" :MfFMAZXCS3="MfFMAZXCS3" :MfFMAZXCS4="MfFMAZXCS4"></MfFMAZX>


	</div>
</template>

<script>
	import index from "../../Mfindex/_index/index.js";
	import mflmanyManual from "@/components/MflmanyManual.vue"; //多手操器
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	// import Switchpage from "@/components/Switchpage.vue"; //切换画面组件
	import Historical from "@/components/Historical.vue"; //历史趋势
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import MfMAC from "@/views/MfBoiler/MfMAC/index.vue";
	import MfFMAZX from "@/views/MfBoiler/MFFMA_ZX/index.vue";
	export default {
		name: "index",
		components: {
			mflmanyManual,
			Firstcontrol,
			Historical,
			Rsf,
			inputVal,
			MfMAC,
			MfFMAZX,
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		data: () => {
			return {
				mflmanyManual: false,
				Manualmark: '',
				Manualvar: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.7vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				MfMAC: false,
				MfMACCS1: "",
				MfMACCS2: "",
				MfFMAZXCS1: "",
				MfFMAZXCS2: "",
				MfFMAZXCS3: "",
				MfFMAZXCS4: "",
				MfFMAZX: "",
				MfZQFOZX: "",
				MfZQFOZXSC1: "",
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				changValue: null,

				rightHeight: "",
			};
		},
		mixins: [index],
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		computed: {},
		mounted() {},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					console.log(str.charAt(str.length - 1))
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无操作权限');
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type);
				}

			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.mflindex {
		width: 100vw;
		height: 100vh;
		margin-left: 1vw;
		overflow-x: hidden;
		overflow-y: hidden;
		font-size: 0.9vw;
		color: #fff;

		.fl {
			float: left;
		}

		.mainleft {
			width: 15vw;
			height: 74vh;
			background-image: url("~@/assets/images/MFL_zfxt.png");
			background-size: 100% 100%;
			// border: 1px solid saddlebrown;
			// margin:0 2vw 0 0 ;
			font-size: 0.9vw;
			margin-top: 1vh;
		}

		.mainleft-jg {
			width: 4.8vw;
			height: 74vh;
			//  border:1px solid rgb(41, 235, 7);
		}

		.main-top {
			height: 74vh;

		}

		.main-bottom {
			width: 98vw;
			height: 8vh;
			background-image: url("~@/assets/images/MFL_zfxt1.png");
			background-size: 100% 100%;
			margin: -2.8vh 0 0 0;
		}

		.main-top {
			margin: 2vh 0 0 0.5vw;
		}

		.mainleft-row1 {
			margin: 17vh 0 0 0;
			width: 20vw;
			height: 7vh;
		}

		.mainleft-row2 {
			color: #5ca4a6;
			font-size: 1vw;
			height: 2.5vh;
		}

		.mainleft-row3 {
			margin: 0 0 0 11vw;
			height: 2.5vh;
		}

		.mainleft-row4 {
			margin: 5vh 0 0 -1.4vw;
		}

		.mainleft-jg-row1-jg {
			margin: 11.7vh 0 0 0vw;
			;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 0.9vw;
			// margin-right: 0.1vw;
			color: #fff;
			margin-right: 0.1vw;
			margin-top: 0.1vh;
			cursor: pointer;
		}

		.row1-span {
			margin: 0 0 0 2vw;
		}

		.valuex {
			width: 3vw;
			text-align: center;
			height: 2.5vh;
			line-height: 2.5vh;
			cursor: pointer;
			// border:1px saddlebrown solid;
		}

		.valuexx {
			width: 4.5vw;
			text-align: center;
			height: 2.5vh;
			line-height: 2.5vh;
			cursor: pointer;
			//  border:1px saddlebrown solid;
		}

		.widthx {
			color: #5ca4a6;
			width: 4vw;
			text-align: right;
			height: 2.5vh;
			line-height: 2.5vh;
		}

		.widthx1 {
			color: #5ca4a6;
			// width:4vw;
			text-align: center;
			height: 2.5vh;
			line-height: 2.5vh;
		}

		.danwei {
			height: 2.5vh;
			line-height: 2.5vh;
			font-size: 0.8vw;
		}

		.color1 {
			color: #1e8df3;
		}

		.mainleft-jg-row1 {
			margin-top: 27.6vh;
			height: 2vh;
		}

		.mainleft-jg1 {
			margin: 2vh 0 0 1vw;
		}

		.mainleft-row4-span {
			width: 10vw;
			height: 2vh;
		}

		.mainleft-row4-1 {
			margin: 5vh 0 0 3.4vw;
			// border: 1px saddlebrown solid;
		}

		.mainleft-row5 {
			margin: 4vh 0 0 0;
		}

		.fengl {
			margin: 2vh 0 0 0;
			width: 17vw;
			height: 12vh;
		}

		.buttonfl {
			width: 3vw;
			height: 2.8vh;
			background-color: #00baff;
			text-align: center;
			line-height: 3vh;
			cursor: pointer;
		}

		.flbutton {
			height: 4vh;
			;
		}

		.luhao {
			height: 1.5vh;

			// line-height: 2vh;
			.luhao-row1 {
				width: 10vw;
				text-align: center;
				height: 1.5vh;
			}

			.luhao-row2 {
				width: 10vw;
				text-align: center;
				padding-left: 10vw;
				height: 1.5vh;
			}
		}

		.buttonjg {
			margin: 1vh 0 0 0vw;
		}

		.buttonjgx {
			margin: -3vh 0 0 5vw;
		}

		.buttonjg1 {
			margin: -1vh 0 0 9vw;
		}

		.mainleft-row6 {
			margin: 2.5vh 0 0 11vw;
			height: 2vh;
		}

		.mainleft-row7-span1 {
			margin-left: -1vw;
		}

		.mainleft-row7-span2 {
			margin-left: 7vw;
		}

		.bottonx-jg {
			margin: 4vh 0 0 0;
		}

		.button-bottom {
			width: 10vw;
			height: 3vh;
			background-color: #cfe5e6;
			color: #000;
			text-align: center;
			line-height: 3vh;
			font-size: 1.1vw;

		}

		.button-bottom-jg {
			margin: -5.8vh 0 0 10vw;
		}

		.button-bottom-jg2 {
			margin: -1.5vh 0 0 10vw;
		}

		.button-bottom-jg3 {
			margin: 2.7vh 0 0 -10vw;
		}
	}
</style>
